import { HttpClient } from '@angular/common/http';
import { Injectable, isDevMode, OnDestroy } from '@angular/core';

import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';

import { JwtHelperService } from '@auth0/angular-jwt';
import { environment } from '@ph-env/environment';
import { fromEvent, Observable, Subscription, throwError, timer } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';

import { PhNotificationComponent, PhNotificationType } from '@ph-shared/components';
import { logout } from '@ph-store/user/user.actions';

import { RefreshTokenResponse } from './refreshtoken.response';
import { TokenService } from './token.service';
import { PhDialog } from '../dialog';
import { LazyOktaService } from '../okta/lazy-okta.service';
import { PhOverlayRef } from '../overlay';

@Injectable({ providedIn: 'root' })
export class AuthService implements OnDestroy {
  private notificationRef: PhOverlayRef<PhNotificationComponent>;

  private _openTimeoutModal: boolean = false;
  private sessionTime = 5400000;
  private warningTime = 60000;
  private sessionTimer: Observable<number>;

  // subscriptions
  private tokenTimerSubscription: Subscription;
  private sessionTimerSubscription: Subscription;
  private resetInactivitySubscription: Subscription;
  private appVersionSubscription: Subscription;
  private tokenApiCallSubscription: Subscription;

  constructor(
    private httpClient: HttpClient,
    private jwtHelper: JwtHelperService,
    private store: Store,
    private translate: TranslateService,
    private oktaService: LazyOktaService,
    private tokenService: TokenService,
    private dialog: PhDialog
  ) {}

  startInactivityTimer(): void {
    this.resetInactivitySubscription?.unsubscribe();
    this.resetInactivitySubscription = fromEvent(document, 'click').subscribe(() => this.startSessionTimer());
  }

  startSessionTimer(): void {
    this.cancelSessionTimer();
    // refactor -- gotta use pipes -- maps have to be passed through a pipe function
    this.sessionTimer = timer(this.sessionTime - this.warningTime, 1000).pipe(
      map((value: number) => this.warningTime - value * 1000)
    );

    this.sessionTimerSubscription = this.sessionTimer.subscribe((n) => {
      const sessionDialogMessage = (time: number) =>
        `${this.translate.instant('sessionPopup.body')}\n${this.translate.instant(
          'sessionPopup.expiringIn'
        )} ${new Date(time).toISOString().substring(14, 19)}`;

      this.notificationRef?.updateData({ message: sessionDialogMessage(n) });
      if (n === 0) {
        this._cancelAllSubscriptions();
        this.notificationRef.close();
        this.store.dispatch(logout({ newVersion: false, version: '' }));
      }
      if (!this._openTimeoutModal && n !== 0) {
        this._showSessionExpiryNotification(sessionDialogMessage(n));
      }
    });
  }

  _showSessionExpiryNotification(message: string): void {
    this.notificationRef = this.dialog.open(PhNotificationComponent, {
      data: {
        actions: [{ act: (ref) => ref.close(), text: this.translate.instant('sessionPopup.ok') }],
        value: { title: this.translate.instant('sessionPopup.title'), message },
        type: PhNotificationType.inaudit,
      },
    });

    this.notificationRef
      .afterClosed()
      .pipe(take(1))
      .subscribe(() => {
        this.notificationRef = null;
        this.startSessionTimer();
      });

    this._openTimeoutModal = true;
  }

  cancelSessionTimer() {
    if (this.sessionTimerSubscription) {
      this.sessionTimerSubscription.unsubscribe();
      this._openTimeoutModal = false;
    }
  }

  isAuthenticated(): Promise<boolean> {
    if (
      environment.auth.sso &&
      (!isDevMode() || !localStorage.getItem('disable-okta') || localStorage.getItem('disable-okta') === 'false')
    ) {
      return Promise.all([this.oktaService.getOktaAuth(), this._davinciTokenValid()])
        .then(([oktaAuth, davinciTokenValid]) => oktaAuth.isAuthenticated() && davinciTokenValid)
        .catch(() => false);
    } else {
      return this._davinciTokenValid();
    }
  }

  scheduleTokenRenewal(): void {
    // cancel any token renewal subscriptions
    this.cancelTokenRenewal();
    // check authentication
    this.isAuthenticated().then((isAuthenticated) => {
      if (!isAuthenticated) {
        this._cancelAllSubscriptions();
        this.store.dispatch(logout({ newVersion: false, version: '' }));

        return;
      } else {
        const token = this.tokenService.getToken();
        const tokenExpiration = this.jwtHelper.getTokenExpirationDate(token);
        const now = Date.now();
        const timeBeforeExpirationToStartRenewing = 2 * 60 * 1000;

        // Once the delay time from above is
        // reached, get a new JWT and schedule
        // additional refreshes
        this.tokenTimerSubscription = timer(
          Math.max(1, tokenExpiration.getTime() - timeBeforeExpirationToStartRenewing - now),
          1000
        ).subscribe(() => {
          if (environment.tokenExpirationCheck) {
            this._renewToken();
          }
        });
      }
    });
  }

  cancelTokenRenewal(): void {
    this.tokenTimerSubscription?.unsubscribe();
  }

  ngOnDestroy(): void {
    this.tokenTimerSubscription?.unsubscribe();
    this.sessionTimerSubscription?.unsubscribe();
    this.resetInactivitySubscription?.unsubscribe();
    this.appVersionSubscription?.unsubscribe();
    this.tokenApiCallSubscription?.unsubscribe();
  }

  private _davinciTokenValid(): Promise<boolean> {
    return new Promise((resolve) => {
      // Check whether the current time is past the
      // access token's expiry time
      const token = this.tokenService.getToken();
      const user = JSON.parse(localStorage.getItem('userState'));
      if (token && user.isLoggedIn) {
        try {
          const tokenExpiration = this.jwtHelper.getTokenExpirationDate(token);
          resolve(Date.now() < tokenExpiration.getTime() || !environment.tokenExpirationCheck);
        } catch (error) {
          this.tokenService.deleteToken();
          resolve(false);
        }
      } else {
        resolve(false);
      }
    });
  }

  private _cancelAllSubscriptions() {
    this.tokenTimerSubscription?.unsubscribe();
    if (this.sessionTimerSubscription) {
      this.sessionTimerSubscription.unsubscribe();
      this._openTimeoutModal = false;
    }
    this.resetInactivitySubscription?.unsubscribe();
  }

  private _renewToken(): void {
    // Dispatch calling refresh token
    this.tokenApiCallSubscription?.unsubscribe();

    this.tokenApiCallSubscription = this.httpClient
      .get<RefreshTokenResponse>(`${environment.apiUrl}/refreshtoken`)
      .pipe(catchError((error) => throwError(() => error)))
      .subscribe((data) => {
        this.tokenService.setToken(data.token);
        this.scheduleTokenRenewal();
      });
  }
}
