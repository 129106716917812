<div class="chip">
  <div class="chip__content">
    <ng-content />
  </div>
  @if (hasCloseBtn()) {
    <div class="chip__nav">
      <div class="chip__toggle" (click)="close.emit()"></div>
    </div>
  }
</div>
