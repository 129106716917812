import { AsyncPipe } from '@angular/common';
import { Component, effect, OnInit, Signal } from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { Title } from '@angular/platform-browser';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';

import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';

import { environment } from '@ph-env/environment';
import { ApplicationCode } from '@ph-env/environment.model';
import { notificationDefaultDuration } from '@ph-static-data/notification-durations';
import { filter, take } from 'rxjs/operators';

import { ErrorService } from '@ph-core/services/error/error.service';
import { InitialLoaderComponent, LoaderComponent, PhNotificationType } from '@ph-shared/components';
import { selectInitialLoading, selectSubsequentLoading } from '@ph-store/config/config.selectors';
import { loadPrismicData } from '@ph-store/prismic/prismic.actions';
import { selectBrandFavicon } from '@ph-store/prismic/prismic.selectors';

import { NotificationService } from './core/services/notification';
import { SupportedLanguageCodes } from './static-data/languages';
import { getInitialLayout } from './store/config/config.actions';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [RouterOutlet, AsyncPipe, LoaderComponent, InitialLoaderComponent],
})
export class AppComponent implements OnInit {
  initialLoading: Signal<boolean> = toSignal(this.store.select(selectInitialLoading));
  loading: Signal<boolean> = toSignal(this.store.select(selectSubsequentLoading));

  constructor(
    private titleService: Title,
    private store: Store,
    private errorService: ErrorService,
    private notificationService: NotificationService,
    private router: Router,
    private translate: TranslateService
  ) {
    let languageCode = localStorage.getItem('languageCode') || SupportedLanguageCodes.EnglishCA;

    this.translate.use(languageCode);

    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        takeUntilDestroyed()
      )
      .subscribe(() => {
        languageCode = localStorage.getItem('languageCode');
        if (this.translate.currentLang && this.translate.currentLang !== languageCode) {
          this.translate.use(languageCode);
          // For cases when currentLang is not defined yet, happens on page refresh
        } else if (!this.translate.currentLang) {
          setTimeout(() => this.translate.use(languageCode));
        }
      });

    effect(() => {
      if (this.errorService.error().show) {
        this.notificationService.push({
          value: { ...this.errorService.error(), title: 'Warning!' },
          type: PhNotificationType.denied,
          duration: this.errorService.error().duration || notificationDefaultDuration,
        });
      }
    });
  }

  ngOnInit(): void {
    localStorage.setItem('domain', environment.application);
    this.store.dispatch(loadPrismicData());
    this.store.dispatch(getInitialLayout({ application: environment.application }));
    this._setTitle(environment.application);

    this.store
      .select(selectBrandFavicon)
      .pipe(
        filter((faviconUrl) => !!faviconUrl),
        take(1)
      )
      .subscribe((faviconUrl: string) => {
        const favIcon: HTMLLinkElement = document.querySelector('#metaFavicon');
        if (favIcon) {
          favIcon.href = faviconUrl;
        }
      });
  }

  private _setTitle(application: ApplicationCode): void {
    let title = '';
    switch (application) {
      case 'extraprotectca':
        title = 'Extra Protect Canada';
        break;
      case 'lfsprotectionca':
        title = 'LFS Protection';
        break;
      case 'tfsprotectionca':
        title = 'TFS Protection';
        break;
      case 'sfsprotectionca':
        title = 'SFS Protection';
        break;
      case 'oneearthprotectionca':
        title = 'One Earth Protection';
        break;
      default:
        break;
    }
    this.titleService.setTitle(title);
  }
}
