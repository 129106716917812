import { createReducer, on } from '@ngrx/store';

import { RecentQuotes } from '@ph-model/api/response/recent-quotes.response.model';
import { UserResponse, Markup } from '@ph-model/login';
import { CurrentUsers, AvailableRole } from '@ph-model/response/getuser.response';
import { checkPendingContractsWarningsSuccess, getPendingContractsSuccess } from '@ph-store/remit/remit.actions';

import {
  loginSuccess,
  emulateDealer,
  resetDealer,
  loginFailure,
  saveMarkup,
  updatePendingContractsCount,
  getRecentQuotesSuccess,
  deleteQuoteSuccess,
  updateVersion,
  getUsersSuccess,
  logoutSuccess,
} from './user.actions';

export const featureKey = 'userState';

export interface UserState {
  isLoggedIn: boolean;
  user: UserResponse;
  originalUser: UserResponse;
  error: string;
  success: boolean;
  sessionExpired: boolean;
  markup: Markup;
  recentQuotes: RecentQuotes[];
  appVersion: string;
  pendingContractsCount: number;
  emulatedDealer: boolean;
  // Dealer Admin
  users: CurrentUsers[];
  roles: AvailableRole[];
}

const InitialState: UserState = {
  user: undefined,
  originalUser: undefined,
  recentQuotes: [],
  isLoggedIn: false,
  error: '',
  pendingContractsCount: 0,
  success: false,
  sessionExpired: false,
  markup: new Markup(),
  appVersion: '',
  emulatedDealer: false,
  users: [],
  roles: [],
};

export const reducer = createReducer(
  InitialState,

  on(loginSuccess, (state, { response }): UserState => {
    const user = {
      ...response,
      siteInfo: {
        ...response.siteInfo,
        appEmail2: response.siteInfo.appEmail2 || 'dealersupport@adventureprotect.ca',
        dealerSupportPhoneNumber2: response.siteInfo.dealerSupportPhoneNumber2 || '833-954-0079',
      },
    };

    return {
      ...state,
      user: user,
      originalUser: user,
      markup: response.userDefaults.markup,
      isLoggedIn: true,
      success: true,
    };
  }),

  on(
    loginFailure,
    (state, { error }): UserState => ({
      ...state,
      success: false,
      error,
    })
  ),

  on(
    emulateDealer,
    (state, { userResponse }): UserState => ({
      ...state,
      originalUser: JSON.parse(JSON.stringify(state.originalUser)),
      user: userResponse,
      emulatedDealer: true,
    })
  ),
  on(
    resetDealer,
    (state): UserState => ({
      ...state,
      user: state.originalUser,
      emulatedDealer: false,
    })
  ),

  on(
    saveMarkup,
    (state, { markup }): UserState => ({
      ...state,
      markup,
    })
  ),

  on(
    updatePendingContractsCount,
    (state, { count }): UserState => ({
      ...state,
      pendingContractsCount: count,
    })
  ),

  on(
    getPendingContractsSuccess,
    checkPendingContractsWarningsSuccess,
    (state, { contracts }): UserState => ({
      ...state,
      pendingContractsCount: contracts.pendingContracts.length,
    })
  ),

  on(
    getRecentQuotesSuccess,
    (state, { recentQuotes }): UserState => ({
      ...state,
      recentQuotes,
    })
  ),

  on(
    deleteQuoteSuccess,
    (state, { response }): UserState => ({
      ...state,
      recentQuotes: response,
    })
  ),

  on(
    updateVersion,
    (state, { version }): UserState => ({
      ...state,
      appVersion: version,
    })
  ),

  on(
    getUsersSuccess,
    (state, { userResponse }): UserState => ({
      ...state,
      users: userResponse.currentUsers,
      roles: userResponse.availableRoles,
    })
  ),

  on(
    logoutSuccess,
    (state): UserState => ({
      ...state,
      user: undefined,
      originalUser: undefined,
      emulatedDealer: false,
      recentQuotes: [],
      isLoggedIn: false,
      error: '',
      success: false,
      sessionExpired: false,
      markup: new Markup(),
    })
  )
);
