import { environment } from '@ph-env/environment';

import { GetProductsRequest } from './product.request';

interface RequestBase {
  version: string;
  methodName: string;
  applicationName: string;
  channel: string;
  vendorName: string;
  userName: string;
  password: string;
}

export class AggregatorRequest {
  dealer: string;
  vin: string;
  saleDate: string;
  odometer: number;
  inServiceDate: string;
  financeType: string;
  isAfterSale: string;
  vehiclePurchaseDate: string;
  programName: string;
  vehicleMake: string;
  vehicleYear: number;
  vehicleModel: string;
  vehicleTrim: string;
  vehicleCondition: string;
  groupCode: string;
  financeAmount: number | undefined;
  vehicleCost: number | undefined;
  vehicleMSRP: number | undefined;
  customerState: string;
  lienholderName: string;
  languageCode: string;
  requestBase: RequestBase;
  customerTaxExempt?: boolean;
  negativeEquity?: boolean;

  constructor(request: GetProductsRequest) {
    this.vin = request.vin;
    this.saleDate = request.saleDate;
    this.odometer = +request.odometer;
    this.financeType = request.financeType;
    this.vehicleMake = request.vehicleMake;
    this.vehicleModel = request.vehicleModel;
    this.financeAmount = +request.financeAmount;
    this.vehicleCost = +request.vehicleCost;
    this.vehicleMSRP = +request.msrp;
    this.lienholderName = request.lienholderName;
    this.languageCode = request.languageCode;

    if (environment.features.supportCustomerTaxExempt) {
      this.customerTaxExempt = request.customerTaxExempt;
    }

    if (environment.features.supportNegativeEquity) {
      this.negativeEquity = request.negativeEquity;
    }

    // Optional props:
    if (request.inServiceDate) {
      this.inServiceDate = request.inServiceDate;
    }
    if (request.isAfterSale) {
      this.isAfterSale = request.isAfterSale;
    }
    if (request.vehiclePurchaseDate) {
      this.vehiclePurchaseDate = request.vehiclePurchaseDate;
    }
    if (request.programName) {
      this.programName = request.programName;
    }
    if (request.vehicleYear) {
      this.vehicleYear = +request.vehicleYear;
    }
    if (request.vehicleTrim) {
      this.vehicleTrim = request.vehicleTrim;
    }
    if (request.vehicleCondition) {
      this.vehicleCondition = request.vehicleCondition;
    }
    if (request.customerState) {
      this.customerState = request.customerState;
    }

    this.groupCode = '';
  }
}
