@if ({ index: currentStepIdx() }; as context) {
  @if (steps(); as steps) {
    <ul class="stepper">
      @for (step of steps; track step; let index = $index; let first = $first; let last = $last) {
        <li>
          <a
            class="stepper__item"
            [ngClass]="{
              'stepper__item--valid': context.index !== index && step.visited && step.valid,
              'stepper__item--active': context.index === index,
              'stepper__item--incomplete': context.index !== index && step.visited && !step.valid,
              'stepper__item--disabled': !step.visited,
              first: first,
              last: last
            }"
            (click)="goToStep(index)"
          >
            <div class="stepper__number">
              <span>{{ index + 1 }}</span>
            </div>
            <span class="stepper__title">{{ step.title | translate }}</span>
          </a>
        </li>
      }
    </ul>
  }
}
