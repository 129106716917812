import { CdkFixedSizeVirtualScroll, CdkVirtualForOf, CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { AsyncPipe, NgTemplateOutlet } from '@angular/common';
import { Component, contentChild, input, InputSignal, TemplateRef } from '@angular/core';

@Component({
  selector: 'ph-virtual-list',
  templateUrl: './virtual-list.component.html',
  styleUrls: ['./virtual-list.component.scss'],
  standalone: true,
  imports: [CdkVirtualScrollViewport, CdkFixedSizeVirtualScroll, CdkVirtualForOf, NgTemplateOutlet, AsyncPipe],
  host: {
    '[style.--ph-virtual-list-gap]': 'gap()',
  },
})
export class PhVirtualListComponent {
  template = contentChild(TemplateRef);
  gap: InputSignal<string> = input.required({ transform: (val: string) => `${val}px` });
  items: InputSignal<unknown[]> = input.required();

  // itemSize - item height to reserve space in scrollable container(fix for jumping issue while scrolling)
  itemSize: InputSignal<number> = input.required();

  trackByID(index: number): number {
    return index;
  }
}
