import { AsyncPipe, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';

import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { catchError, map, startWith, tap } from 'rxjs/operators';

import { PH_OVERLAY_DATA } from '@ph-core/services/overlay';
import { OperationsByDatePipe } from '@ph-shared/pipes';

import { ContractOperationRecord } from './models/contract-operation-record.model';
import { ContractHistoryService } from './services/contract-history.service';
import { ContractOperationRecordComponent } from '../contract-operation-record/contract-operation-record.component';
import { SpinnerComponent } from '../spinner/spinner.component';

@Component({
  templateUrl: './contract-history.component.html',
  styleUrls: ['./contract-history.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    AsyncPipe,
    ContractOperationRecordComponent,
    NgTemplateOutlet,
    OperationsByDatePipe,
    SpinnerComponent,
    TranslateModule,
  ],
  providers: [ContractHistoryService],
})
export class ContractHistoryComponent implements OnInit {
  viewData$:
    | Observable<{
        activityHistory: ContractOperationRecord[] | undefined;
        loading: boolean;
        error: string | undefined;
      }>
    | undefined;

  private _error$ = new BehaviorSubject<string | undefined>(undefined);
  private _loading$ = new BehaviorSubject<boolean>(true);
  private _activityHistory$: Observable<ContractOperationRecord[]> | undefined;

  constructor(
    @Inject(PH_OVERLAY_DATA) private data: { contractId: string | undefined },
    private contractHistoryService: ContractHistoryService
  ) {}

  ngOnInit(): void {
    if (this.data.contractId) {
      this._loading$.next(true);
      this._error$.next(undefined);
      this._activityHistory$ = this.contractHistoryService.getContractHistory(this.data.contractId).pipe(
        tap(() => {
          this._loading$.next(false);
          this._error$.next(undefined);
        }),
        catchError((error) => {
          this._loading$.next(false);
          this._error$.next(error);

          return of([]);
        }),
        startWith([])
      );
      this.viewData$ = combineLatest([this._activityHistory$, this._loading$, this._error$]).pipe(
        map(([activityHistory, loading, error]) => ({ activityHistory, loading, error }))
      );
    }
  }
}
