import { Injectable } from '@angular/core';

import { CookieService } from 'ngx-cookie-service';

@Injectable({ providedIn: 'root' })
export class TokenService {
  constructor(private cookieService: CookieService) {}

  setToken(token: string | undefined) {
    if (token) {
      this.cookieService.set('token', token);
    }
  }

  getToken(): string | undefined {
    return this.cookieService.get('token');
  }

  deleteToken(): void {
    this.cookieService.delete('token');
  }
}
