import { formatCurrency } from '@angular/common';
import { Component, Inject, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';

import { environment } from '@ph-env/environment';
import { SupportedLanguageCodes } from '@ph-static-data/languages';
import { download, generateCsv, mkConfig } from 'export-to-csv';
import { take, tap } from 'rxjs/operators';

import { PH_OVERLAY_DATA } from '@ph-core/services/overlay';
import { LazyTemplateService } from '@ph-core/services/template/lazy-template.service';
import { sanitizeDataForCsv } from '@ph-core/utils';
import { ContractType } from '@ph-model/api';
import { PendingContractResponse } from '@ph-model/api/response/pending-contract.response.model';
import { ExportToCSVContract } from '@ph-model/export-to-cvs-contract.model';
import { UserResponse } from '@ph-model/login/user.response.model';
import { PhOverlayCloseDirective } from '@ph-shared/directives';
import { selectBrandLogoUrl } from '@ph-store/prismic/prismic.selectors';
import { selectSubmittedPendingContracts } from '@ph-store/remit/remit.selectors';
import { selectUser } from '@ph-store/user/user.selectors';

import { IconComponent } from '../icon/icon-component';
import { PhButtonComponent } from '../ph-button/ph-button.component';
import { AcceptedData } from 'export-to-csv/output/lib/types';

@Component({
  templateUrl: './remit-submitted-popup.component.html',
  styleUrls: ['./remit-submitted-popup.component.scss'],
  standalone: true,
  imports: [TranslateModule, IconComponent, PhButtonComponent, PhOverlayCloseDirective],
})
export class RemitSubmittedPopupComponent {
  errorInContracts: boolean = false;
  displayErrors: { name: string; error: string }[] = [];
  atLeastOneSuccessfulContract: boolean = true;
  supportContractRemittance: boolean = environment.features.supportContractRemittance;

  private _submittedPendingContracts: ContractType[] = [];
  private _navbarLogo: string = '';
  private _pendingContractResponse: PendingContractResponse;
  private templateService = inject(LazyTemplateService);

  constructor(
    private store: Store,
    @Inject(PH_OVERLAY_DATA) private data: { contracts: PendingContractResponse }
  ) {
    this._pendingContractResponse = this.data.contracts;
    this.store
      .select(selectBrandLogoUrl)
      .pipe(
        tap((logoUrl: string) => (this._navbarLogo = logoUrl)),
        takeUntilDestroyed()
      )
      .subscribe();

    this.store
      .select(selectSubmittedPendingContracts)
      .pipe(
        tap((submittedContracts: ContractType[]) => {
          this._checkForErroredContracts(this._pendingContractResponse, submittedContracts);
          this._submittedPendingContracts = submittedContracts;
        }),
        takeUntilDestroyed()
      )
      .subscribe();
  }

  printRemit(): void {
    const successfulContracts = this._prepareSuccessfulContracts();
    const total = this._getTotalDealerCost(successfulContracts);
    this.templateService.printRemit(successfulContracts, true, this._navbarLogo, total);
  }

  exportClicked(): void {
    this.store
      .select(selectUser)
      .pipe(take(1))
      .subscribe((userData: UserResponse) => {
        const successfulContracts = this._prepareSuccessfulContracts();

        const headers: { key: string; displayLabel: string }[] = [
          { key: 'dealerNumber', displayLabel: 'DEALER NUMBER' },
          { key: 'customerName', displayLabel: 'CUSTOMER NAME' },
          { key: 'year', displayLabel: 'YEAR' },
          { key: 'model', displayLabel: 'MODEL' },
          { key: 'vin', displayLabel: 'VIN' },
          { key: 'product', displayLabel: 'PRODUCT' },
          { key: 'term', displayLabel: 'TERM' },
          { key: 'contractNumber', displayLabel: 'CONTRACT#' },
          { key: 'dealerCost', displayLabel: 'DEALER COST' },
          { key: 'totalCost', displayLabel: 'TOTAL COST' },
          { key: 'totalDealerCost', displayLabel: 'Total Dealer Cost:' },
        ];
        const data: ExportToCSVContract[] = successfulContracts.map((contract: ContractType) => ({
          dealerNumber: userData.cmsDealerNumber, // will this be correct every time?
          customerName: contract.firstName + ' ' + contract.lastName,
          year: contract.year,
          model: contract.model,
          vin: contract.vin,
          product: contract.productCode,
          term: contract.termMonths,
          contractNumber: contract.econContractNumber,
          dealerCost: contract.contractDealerCost,
          totalCost: contract.customerCost,
          totalDealerCost:
            successfulContracts && successfulContracts[0] && contract === successfulContracts[0]
              ? formatCurrency(this._getTotalDealerCost(successfulContracts), SupportedLanguageCodes.English, '$')
              : '', // leave remaining cells blank
        }));
        this._exportToCSV(data, this._getFileName(successfulContracts), headers);
      });
  }

  private _getTotalDealerCost(contracts: ContractType[]): number {
    return contracts.reduce((a: number, b: ContractType) => a + b.contractDealerCost, 0);
  }

  private _prepareSuccessfulContracts(): ContractType[] {
    const remittedErrorContracts =
      this._pendingContractResponse?.erroredContracts.remittedErrorContracts.map(
        (c: ContractType) => c.econContractId
      ) || [];
    const declinedErrorContracts =
      this._pendingContractResponse?.erroredContracts.declinedErrorContracts.map(
        (c: ContractType) => c.econContractId
      ) || [];

    const unsuccessfulContractIDs = [...remittedErrorContracts, ...declinedErrorContracts];

    return this._submittedPendingContracts.filter(
      (c: ContractType) => !unsuccessfulContractIDs.includes(c.econContractId)
    );
  }

  private _checkForErroredContracts(
    response: PendingContractResponse,
    submittedContracts: ContractType[] = []
  ): ContractType[] {
    this.errorInContracts = false;
    this.atLeastOneSuccessfulContract = false;
    this.displayErrors = [];

    const contracts = response?.erroredContracts;
    if (contracts) {
      if (contracts.declinedErrorContracts && contracts.declinedErrorContracts.length > 0) {
        this.errorInContracts = true;

        this.displayErrors = contracts.declinedErrorContracts.map((data: ContractType) => {
          return {
            name: `${data.firstName} ${data.lastName} - ${data.description}`,
            error: data.standardResponse.responseDescription,
          };
        });
      }

      if (contracts.remittedErrorContracts && contracts.remittedErrorContracts.length > 0) {
        this.errorInContracts = true;

        this.displayErrors = contracts.remittedErrorContracts.map((data: ContractType) => {
          return {
            name: `${data.firstName} ${data.lastName} - ${data.description}`,
            error: data.standardResponse.responseDescription,
          };
        });
      }
      if (this.displayErrors.length < submittedContracts.length) {
        this.atLeastOneSuccessfulContract = true;
      }

      return submittedContracts.filter((contract: ContractType) => {
        const foundIndx = contracts.remittedErrorContracts.findIndex(
          (x: ContractType) => x.econContractId === contract.econContractId
        );

        return foundIndx <= -1;
      });
    } else if (this._submittedPendingContracts.length) {
      this.atLeastOneSuccessfulContract = true;
    }

    return submittedContracts;
  }

  private _exportToCSV(
    data: ExportToCSVContract[],
    filename: string,
    columnHeaders: { key: string; displayLabel: string }[]
  ): void {
    const csvConfig = mkConfig({ filename, columnHeaders });

    const sanitizedData: Record<string, AcceptedData>[] = sanitizeDataForCsv(
      data as unknown as Record<string, AcceptedData>[]
    );

    const csv = generateCsv(csvConfig)(sanitizedData);

    download(csvConfig)(csv);
  }

  private _getFileName(contracts: ContractType[]): string {
    const prependName = 'Remittance Register';
    if (contracts || contracts[0]) {
      return prependName;
    }
    const firstContract = contracts[0];
    const appendDateName =
      firstContract && contracts.every((c: ContractType) => c.contractGroup2 === firstContract.contractGroup2)
        ? firstContract.contractGroup2
        : firstContract.contractGroup1;

    return prependName + ' ' + appendDateName;
  }
}
