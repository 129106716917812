import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { ChangeDetectionStrategy, Component, input, InputSignal } from '@angular/core';

@Component({
  selector: 'ph-form-control-label',
  templateUrl: 'form-control-label.component.html',
  styleUrls: ['./form-control-label.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  host: {
    '[class.invalid]': 'invalid()',
    '[class.disabled]': 'disabled()',
  },
})
export class FormControlLabelComponent {
  label: InputSignal<string> = input.required();
  for: InputSignal<string | undefined> = input(undefined);
  required: InputSignal<boolean | string> = input(false, { transform: coerceBooleanProperty });
  invalid: InputSignal<boolean | string> = input(false, { transform: coerceBooleanProperty });
  disabled: InputSignal<boolean | string> = input(false, { transform: coerceBooleanProperty });
}
