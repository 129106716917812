import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { v4 as randomUUID } from 'uuid';

import { PhNotificationItem, PhNotificationPanelComponent } from '@ph-shared/components';

import {
  PhOverlay,
  PhOverlayHorizontalPosition,
  PhOverlayPositionBuilder,
  PhOverlayVerticalPosition,
} from '../overlay';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  private _notifications$: BehaviorSubject<PhNotificationItem[]> = new BehaviorSubject<PhNotificationItem[]>([]);

  constructor(
    private o: PhOverlay,
    private pb: PhOverlayPositionBuilder
  ) {
    this.o.attach(PhNotificationPanelComponent, {
      position: this.pb.position({
        horizontal: PhOverlayHorizontalPosition.end,
        vertical: PhOverlayVerticalPosition.top,
      }),
      data: { notificationService: this },
    });
  }

  notifications$(): Observable<PhNotificationItem[]> {
    return this._notifications$.asObservable();
  }

  clear(): void {
    this._notifications$.next([]);
  }

  push(value: PhNotificationItem): void {
    this._notifications$.pipe(take(1)).subscribe((notifications: PhNotificationItem[]) => {
      this._notifications$.next([{ ...value, id: randomUUID() }, ...notifications]);
    });
  }

  remove(notificationID: string): void {
    this._notifications$.pipe(take(1)).subscribe((value: PhNotificationItem[]) => {
      const notifications = [...value];
      const index = notifications.findIndex(({ id }) => notificationID === id);

      if (index !== -1) {
        notifications.splice(index, 1);
        this._notifications$.next(notifications);
      }
    });
  }
}
