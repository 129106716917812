import { Injectable, Injector, ProviderToken } from '@angular/core';

import { Store } from '@ngrx/store';

import { OktaAuth } from '@okta/okta-auth-js';

import { resetOktaLoading, setOktaLoading } from '@ph-store/config/config.actions';

import { OktaService } from './okta.service';

@Injectable({
  providedIn: 'root',
})
export class LazyOktaService {
  private _oktaService: OktaService | undefined;

  constructor(
    private injector: Injector,
    private store: Store
  ) {}

  async getOktaAuth(): Promise<OktaAuth> {
    const service = await this._initOktaService();

    return service.getOktaAuth();
  }

  async getOktaWidget() {
    const service = await this._initOktaService();

    return service.getOktaWidget();
  }

  async oktaSignOut(): Promise<void | boolean> {
    const service = await this._initOktaService();

    return service.oktaSignOut();
  }

  private async get<T>(providerLoader: () => Promise<ProviderToken<T>>): Promise<T> {
    return this.injector.get(await providerLoader());
  }

  private async _initOktaService(): Promise<OktaService> {
    if (!this._oktaService) {
      this.store.dispatch(setOktaLoading());
      this._oktaService = await this.get<OktaService>(() => import('./okta.service').then((m) => m.OktaService));
      this.store.dispatch(resetOktaLoading());
    }

    return Promise.resolve(this._oktaService);
  }
}
