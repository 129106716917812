import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Action, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';

import { environment } from '@ph-env/environment';
import { tireCoverageCodesAllowedInSameCart } from '@ph-static-data/tire-product-codes';
import { forkJoin, Observable, of, throwError } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap, tap } from 'rxjs/operators';

import { FinanceType, Options, ShoppingFlowType, Vendor } from '@ph-core/enums';
import { PhDialog } from '@ph-core/services/dialog';
import { EContractApiService } from '@ph-core/services/econtract-api.service';
import { FormService } from '@ph-core/services/form.service';
import { NotificationService } from '@ph-core/services/notification';
import { ProductService } from '@ph-core/services/product.service';
import { LazyTemplateService } from '@ph-core/services/template/lazy-template.service';
import { calculateFilters } from '@ph-core/utils/fetch-filters';
import { Coverage, InsuranceResponseModel } from '@ph-model/api/response/insurance.response.model';
import { CartItem } from '@ph-model/cart/cart-item.model';
import { CartTax } from '@ph-model/cart/cart-tax.model';
import { Cart } from '@ph-model/cart/cart.model';
import { LoadQuote } from '@ph-model/cart/load-quote.model';
import { SaveQuote } from '@ph-model/cart/save-quote.model';
import { SubmitCartModel } from '@ph-model/cart/submit-cart.model';
import { ValidatedCart } from '@ph-model/cart/validate-cart.model';
import { SubmitCartResponse } from '@ph-model/checkout/submit_cart/response/submit-cart.response.model';
import { SubmittedCartItem } from '@ph-model/checkout/submit_cart/response/submitted-cart-item.model';
import { CreditInsuranceForm } from '@ph-model/form/credit-insurance-form';
import { FinanceForm } from '@ph-model/form/finance-form';
import { Forms } from '@ph-model/form/form';
import { Markup, UserResponse } from '@ph-model/login';
import { GetProductsRequest } from '@ph-model/request/product.request';
import { SaveAncillaryEContractRequest } from '@ph-model/request/save-ancillary-econtract/save-ancillary-e-contract.request';
import { SavePowerUpEContractRequest } from '@ph-model/request/save-power-up-econtract/save-power-up-e-contract.request';
import { SubmitCartRequest } from '@ph-model/request/submit-cart.request';
import { EContractErrorResponse, EContractSuccessResponse } from '@ph-model/response/e-contract.response';
import { GetProductsResponse } from '@ph-model/response/getproducts.response';
import { PhNotificationComponent, PhNotificationType, RequestQuotePopupComponent } from '@ph-shared/components';
import {
  initializeSteps,
  markAllStepsAsValidAndVisited,
  resetShoppingProgress,
  selectShoppingStepperData,
  ShoppingStepsUrls,
  switchShoppingPath,
  switchToStepByUrl,
} from '@ph-shared/modules/shopping-stepper';
import { convertToCompatibleDateFormat, IComplexDate, openPDFInWindow } from '@ph-shared/utils';
import { selectBrandLogoUrl } from '@ph-store/prismic/prismic.selectors';
import { selectProductRequest, selectProductState } from '@ph-store/product/product.selectors';
import { selectMarkup, selectPendingContracts, selectUser, selectUserState } from '@ph-store/user/user.selectors';
import { loadVehicleQuote, resetProductForm, updateProductForm } from '@ph-store/vehicle/vehicle.actions';
import * as VehicleReducer from '@ph-store/vehicle/vehicle.reducer';
import { VehicleValues } from '@ph-store/vehicle/vehicle.reducer';
import {
  selectDecodeVinResponse,
  selectFinanceAmount,
  selectProductFormValues,
  selectVehicleFinanceForm,
  selectVehicleState,
} from '@ph-store/vehicle/vehicle.selectors';

import { coverageInfoForm } from 'app/static-data/coverage-info-form';
import { creditInsuranceForm } from 'app/static-data/credit-insurance-form';
import { financeForm } from 'app/static-data/finance-form';

import { CartApiClient } from './cart-api.client';
import {
  addAndValidateCartSuccess,
  addTaxes,
  addTaxesSuccess,
  addToCart,
  cartFailure,
  deleteFromCart,
  getCheckoutForm,
  getCheckoutFormSuccess,
  loadQuote,
  loadQuoteSuccess,
  resetCart,
  saveAsQuote,
  saveAsQuoteFailure,
  saveAsQuoteSuccess,
  saveAsUMUCredit,
  saveAsUMUCreditFailure,
  saveAsUMUCreditSuccess,
  saveAsUMUQuote,
  saveAsUMUQuoteFailure,
  saveasUMUQuoteSuccess,
  submitCart,
  submitCartSuccess,
  updateCartItemTaxAmounts,
  updateCheckoutForm,
  updateCreditInsuranceMonthlyPayment,
  updateFinanceMontlyPayment,
} from './cart.actions';
import {
  selectCart,
  selectCartState,
  selectCartTotal,
  selectCheckoutFormValues,
  selectCreditInsuranceForm,
  selectValidatedCart,
} from './cart.selectors';
import { loadProductQuote, resetProducts } from '../product/product.actions';
import { deleteQuote, resetSession, updatePendingContractsCount } from '../user/user.actions';

interface SubmitCartSuccess {
  invalidCart: CartItem[];
  submittedCart: SubmittedCartItem[];
}

function convertToByteArray(input) {
  const sliceSize = 512;
  const bytes = [];

  for (let offset = 0; offset < input.length; offset += sliceSize) {
    const slice = input.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);

    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);

    bytes.push(byteArray);
  }

  return bytes;
}

@Injectable()
export class CartEffects {
  templateService: LazyTemplateService = inject(LazyTemplateService);

  addCart$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(addToCart),
      concatLatestFrom(() => [
        this.store.select(selectProductRequest),
        this.store.select(selectCartState),
        this.store.select(selectUser),
      ]),
      mergeMap(([{ cartItem }, productRequest, cartState, user]) => {
        const cart = [...cartState.validatedCart, JSON.parse(JSON.stringify(cartItem))];
        const request = new Cart(cart, productRequest, user);

        return this.cartApiClient.validateCart(request).pipe(
          map((validatedCart: ValidatedCart) => {
            return addAndValidateCartSuccess({ validatedCart });
          }),
          catchError((error) => of(cartFailure({ error, operation: 'addToCart' })))
        );
      })
    );
  });

  deleteFromCart$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(deleteFromCart),
      concatLatestFrom(() => [
        this.store.select(selectProductRequest),
        this.store.select(selectCartState),
        this.store.select(selectUser),
      ]),
      mergeMap(([action, productRequest, cartState, user]) => {
        const cart = cartState.validatedCart.filter((item: CartItem) => item.key !== action.cartItem.key);
        const request = new Cart(cart, productRequest, user);

        return this.cartApiClient.validateCart(request).pipe(
          map((validatedCart: ValidatedCart) => {
            return addAndValidateCartSuccess({ validatedCart });
          }),
          catchError((error) => of(cartFailure({ error, operation: 'deleteFromCart' })))
        );
      })
    );
  });

  getCheckoutForm$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(getCheckoutForm),
      concatLatestFrom(() => [
        this.store.select(selectProductRequest),
        this.store.select(selectCart),
        this.store.select(selectUser),
      ]),
      switchMap(([, request, cart, user]) => {
        const req = new Cart(cart, request, user);
        const r = {
          ...req,
          application: environment.application,
          programId: user.programId,
        };

        return this.cartApiClient.getCheckoutForm(r).pipe(
          map((forms: Forms) => {
            forms.creditInsuranceForm = creditInsuranceForm;
            forms.coverageInfoForm = coverageInfoForm;
            forms.financeForm = financeForm;

            return getCheckoutFormSuccess({ forms });
          }),
          catchError((error) => of(cartFailure({ error, operation: 'getCheckoutForm' })))
        );
      })
    );
  });

  addTaxes$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(addTaxes),
      concatLatestFrom(() => [
        this.store.select(selectValidatedCart),
        this.store.select(selectUser),
        this.store.select(selectProductRequest),
      ]),
      switchMap(([, validatedCart, user, productRequest]) => {
        const request = new CartTax(user, validatedCart, productRequest);

        return this.cartApiClient.taxCalculation(request).pipe(
          map(({ cart }) => addTaxesSuccess({ validatedCart: cart })),
          catchError((error) => of(cartFailure({ error, operation: 'addTaxes' })))
        );
      })
    );
  });

  submitCart$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(submitCart),
      concatLatestFrom(() => [this.store.select(selectVehicleState), this.store.select(selectUser)]),
      switchMap(([action, vehicleState, user]) =>
        environment.features.useNewSaveContractApi
          ? this._handleSubmitCartWithNewRatesApi(action as { payload: SubmitCartModel }, vehicleState, user)
          : this._handleSubmitCart(action as { payload: SubmitCartRequest }, vehicleState, user)
      )
    );
  });

  submitCartFailure$: Observable<Action> = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(cartFailure),
        tap(({ error }) =>
          this.notificationService.push({
            type: PhNotificationType.cancel,
            value: {
              title: 'Warning!',
              message: error,
            },
          })
        )
      );
    },
    { dispatch: false }
  );

  submitCartSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(submitCartSuccess),
        filter((payload: SubmitCartSuccess) => payload.submittedCart.length > 0),
        concatLatestFrom(() => this.store.select(selectPendingContracts)),
        switchMap(([{ submittedCart }, pendingContracts]: [SubmitCartSuccess, number]) => {
          this.store.dispatch(updatePendingContractsCount({ count: pendingContracts + submittedCart?.length }));

          this.store.dispatch(resetProductForm());
          this.store.dispatch(resetProducts());
          this.store.dispatch(resetShoppingProgress());
          this.store.dispatch(resetCart());

          return this.dialog
            .open(PhNotificationComponent, {
              data: {
                type: PhNotificationType.approved,
                actions: [
                  {
                    text: this.translate.instant('insuranceContractSavedPopup.continue'),
                    act: (ref): void => {
                      ref.close();
                      this.store.dispatch(resetShoppingProgress());
                      void this.router.navigate(['/home']);
                    },
                  },
                  {
                    text: this.translate.instant('insuranceContractSavedPopup.close'),
                    act: (ref): void => {
                      ref.close();
                      this.store.dispatch(resetShoppingProgress());
                    },
                  },
                ],
                value: {
                  title: this.translate.instant('insuranceContractSavedPopup.title'),
                  message: this.translate.instant('insuranceContractSavedPopup.body'),
                },
              },
            })
            .afterClosed();
        })
      );
    },
    { dispatch: false }
  );

  saveQuote$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(saveAsQuote),
      concatLatestFrom(() => [
        this.store.select(selectUser),
        this.store.select(selectUserState),
        this.store.select(selectCartState),
        this.store.select(selectVehicleState),
        this.store.select(selectProductState),
      ]),
      switchMap(([, user, userState, cartState, vehicleState, productState]) => {
        const productRequest = productState.productsRequest;
        let generatedProductRequest: GetProductsRequest;
        if (!Object.keys(productRequest).length && environment.features.supportChargerFlow) {
          generatedProductRequest = GetProductsRequest.setGetProductsRequestByState(user, cartState, vehicleState);
        } else {
          generatedProductRequest = productRequest;
        }

        const quote = new SaveQuote(
          generatedProductRequest,
          {
            ...vehicleState,
            values: {
              ...vehicleState.values,
              vin:
                vehicleState.values.vin ||
                vehicleState.values.pid ||
                vehicleState.values.hin ||
                vehicleState.values.tin ||
                vehicleState.chargerForm.serialNumber,
            },
          },
          cartState,
          userState
        );

        if (user.isRelatedDealer) {
          quote.productState = productState;
        }
        quote.cartState.checkoutFormValues.customer.postalCode = quote.cartState.checkoutFormValues.customer.postalCode
          ? this.formService.getFormattedPostalCode(quote.cartState.checkoutFormValues.customer.postalCode)
          : null;
        quote.cartState.checkoutFormValues.cosigner.postalCode = quote.cartState.checkoutFormValues.cosigner.postalCode
          ? this.formService.getFormattedPostalCode(quote.cartState.checkoutFormValues.cosigner.postalCode)
          : null;

        if (
          Object.keys(quote.cartState.checkoutFormValues.lienholder || {}).length > 0 &&
          quote.cartState.checkoutFormValues.lienholder
        ) {
          quote.cartState.checkoutFormValues.lienholder.postalCode = quote.cartState.checkoutFormValues.lienholder
            ? this.formService.getFormattedPostalCode(quote.cartState.checkoutFormValues.lienholder.postalCode)
            : null;
        }

        return this.cartApiClient.saveQuote(quote).pipe(
          map(() => saveAsQuoteSuccess()),
          catchError(() => of(saveAsQuoteFailure()))
        );
      })
    );
  });

  saveAsQuoteSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(saveAsQuoteSuccess),
        switchMap(() =>
          this.dialog
            .open(PhNotificationComponent, {
              data: {
                type: PhNotificationType.approved,
                actions: [
                  {
                    text: this.translate.instant('quoteSavedPopup.print'),
                    act: (ref) => ref.close({ open: true }),
                  },
                  { text: this.translate.instant('quoteSavedPopup.continue'), act: (ref) => ref.close() },
                ],
                value: {
                  title: this.translate.instant('quoteSavedPopup.title'),
                  message: this.translate.instant('quoteSavedPopup.body'),
                },
              },
            })
            .afterClosed()
        ),
        filter(Boolean),
        concatLatestFrom(() => this.store.select(selectBrandLogoUrl)),
        tap(([, logoUrl]: [unknown, string]) => this.templateService.printQuote(logoUrl))
      );
    },
    { dispatch: false }
  );

  saveUMUQuote$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(saveAsUMUQuote),
      switchMap(({ insRequest }) => {
        return this.cartApiClient.getRequestQuote(insRequest).pipe(
          map((requestedQuote: { insResponse: InsuranceResponseModel }) => {
            if (
              requestedQuote.insResponse.generalInfo.covErrors &&
              requestedQuote.insResponse.generalInfo.covErrors.length > 0
            ) {
              return saveAsUMUQuoteFailure({
                error: requestedQuote.insResponse.generalInfo.covErrors[0].covError,
              });
            }

            if (requestedQuote.insResponse.insurance.coverage === null) {
              return saveAsUMUQuoteFailure({
                error: requestedQuote.insResponse.generalInfo.applicationResponse,
              });
            }

            return saveasUMUQuoteSuccess({ requestedQuote });
          }),
          catchError(() => of(saveAsUMUQuoteFailure({ error: 'Generic error ' })))
        );
      })
    );
  });

  saveUMUQuoteSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(saveasUMUQuoteSuccess),
        tap(() => this.dialog.open(RequestQuotePopupComponent)),
        map(({ requestedQuote }) => requestedQuote.insResponse.insurance.coverage),
        concatLatestFrom(() => this.store.select(selectCreditInsuranceForm)),
        tap(([umuCoverages, insuranceValues]: [Coverage[], CreditInsuranceForm]) => {
          const updatedLoanAmount = umuCoverages.reduce(
            (total, coverage) => total + Number(coverage.premium) + Number(coverage.tax),
            Number(insuranceValues.loanAmount)
          );

          this.store.dispatch(
            updateCheckoutForm({
              updatedFields: {
                creditInsuranceForm: {
                  ...insuranceValues,
                  loanAmount: +updatedLoanAmount.toFixed(2),
                },
              },
            })
          );
        })
      );
    },
    { dispatch: false }
  );

  saveUMUQuoteFailure$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(saveAsUMUQuoteFailure),
        switchMap(({ error }) =>
          this.dialog
            .open(PhNotificationComponent, {
              data: {
                type: PhNotificationType.cancel,
                actions: [{ text: 'Close', act: (ref) => ref.close() }],
                value: {
                  title: 'Quote saving failed',
                  message: error,
                },
              },
            })
            .afterClosed()
        )
      );
    },
    { dispatch: false }
  );

  saveUMUCredit$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(saveAsUMUCredit),
      switchMap(({ request, locale }) => {
        return this.cartApiClient.getUMUCredit(request, locale).pipe(
          map((response) => {
            if (response.insResponse.responseStatus === '1' || response.insResponse.responseStatus === '') {
              return saveAsUMUCreditFailure({ error: response.insResponse.applicationResponse });
            }

            const pdf = response.insResponse.applicationResponse;

            if (pdf) {
              const blob = atob(pdf);
              const file = new Blob(convertToByteArray(blob), { type: 'application/pdf' });
              const fileURL = URL.createObjectURL(file);
              window.open(fileURL, '_blank');
            }

            return saveAsUMUCreditSuccess();
          }),
          catchError(() => of(saveAsUMUCreditFailure({ error: 'Generic Error' })))
        );
      })
    );
  });

  saveUMUCreditFailure$: Observable<Action> = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(saveAsUMUCreditFailure),
        tap(({ error }) =>
          this.notificationService.push({
            type: PhNotificationType.cancel,
            value: {
              title: 'Warning!',
              message: error,
            },
          })
        )
      );
    },
    { dispatch: false }
  );

  saveUMUCreditSuccess$: Observable<[Action, ShoppingFlowType]> = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(saveAsUMUCreditSuccess),
        concatLatestFrom(() =>
          this.store
            .select(selectShoppingStepperData)
            .pipe(map((data: { shoppingFlowType: ShoppingFlowType }) => data?.shoppingFlowType))
        ),
        tap(([, flowType]: [Action, ShoppingFlowType]) => {
          if (flowType === ShoppingFlowType.Insurance) {
            this.store.dispatch(resetSession());
          }
          this.notificationService.push({
            type: PhNotificationType.approved,
            value: {
              title: 'Success!',
              message: 'Insurance contract was successfully created!',
            },
            duration: 4000,
          });
        })
      );
    },
    { dispatch: false }
  );

  loadQuote$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadQuote),
      concatLatestFrom(() => [this.store.select(selectUser), this.store.select(selectDecodeVinResponse)]),
      switchMap(([, user, decodeVinResponse]) =>
        this._callLoadQuote(user, decodeVinResponse.vin).pipe(
          map((quote: LoadQuote) => loadQuoteSuccess({ quote })),
          catchError(() => of(cartFailure({ error: 'Generic Error', operation: 'loadQuote' })))
        )
      )
    );
  });

  LoadQuoteSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(loadQuoteSuccess),
        concatLatestFrom(() => this.store.select(selectMarkup)),
        tap(([{ quote }, markup]: [{ quote: LoadQuote }, Markup]) => {
          const productQuote = this._mapProductsFromLoadQuote(quote, markup);

          // change cartFound value to false as this quote was loaded and not decoded manually
          quote.VehicleState.vin.cartFound = false;

          this.store.dispatch(loadVehicleQuote({ loadQuote: quote }));
          this.store.dispatch(loadProductQuote(productQuote));

          if (environment.features.useQuoteOnlyEndpoint) {
            const name: ShoppingFlowType = quote.VehicleState.vehicleFormEnabled
              ? ShoppingFlowType.Ancillary
              : ShoppingFlowType.PowerUp;
            this.store.dispatch(initializeSteps({ stepDefinitions: environment.shoppingFlow.steps }));
            this.store.dispatch(switchShoppingPath({ name }));
            this.store.dispatch(markAllStepsAsValidAndVisited());
            this.store.dispatch(switchToStepByUrl({ url: ShoppingStepsUrls.CartSummary }));
          } else {
            // navigate to product page
            void this.router.navigate(['/home/shopping']);
          }
        })
      );
    },
    { dispatch: false }
  );

  UpdateMonthlyPayment$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(updateCheckoutForm),
        concatLatestFrom(() => [
          this.store.select(selectCheckoutFormValues),
          this.store.select(selectVehicleFinanceForm),
          this.store.select(selectProductFormValues),
        ]),
        tap(([{ updatedFields }, checkoutValues, vehicleFinance, productForm]) => {
          if (updatedFields.financeForm || updatedFields.creditInsuranceForm) {
            const updatedFinance = this._calculateFinanceMonthlyPayment(
              vehicleFinance.financeType,
              checkoutValues.financeForm
            );
            this.store.dispatch(updateFinanceMontlyPayment({ updatedFields: updatedFinance }));

            const updatedCreditInsurance = this._calculateCreditInsuranceMonthlyPayment(
              vehicleFinance.financeType,
              checkoutValues.creditInsuranceForm as CreditInsuranceForm
            );
            this.store.dispatch(updateCreditInsuranceMonthlyPayment({ updatedFields: updatedCreditInsurance }));

            // update vehicleState.financeForm when checkout finance changed
            const comparedProps = ['apr', 'loanTerm', 'amortizationPeriod', 'residualValue'];
            const checkoutFinanceSubset = this._pickProps(
              { ...checkoutValues.financeForm, ...updatedFinance },
              comparedProps
            );
            const vehicleFinanceSubset = this._pickProps(vehicleFinance, comparedProps);
            if (!this._objectsEqual(checkoutFinanceSubset, vehicleFinanceSubset)) {
              const combinedValues = productForm.apr
                ? { ...vehicleFinance, ...checkoutFinanceSubset, apr: productForm.apr }
                : { ...vehicleFinance, ...checkoutFinanceSubset };
              this.store.dispatch(updateProductForm({ values: combinedValues as Partial<VehicleValues> }));
            }
          }
        })
      );
    },
    { dispatch: false }
  );

  onCartChange$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(updateCartItemTaxAmounts, addToCart, deleteFromCart),
      concatLatestFrom(() => [
        this.store.select(selectCartTotal),
        this.store.select(selectFinanceAmount),
        this.store.select(selectCheckoutFormValues),
      ]),
      map(([, cartTotal, financeAmount, checkoutValues]) =>
        updateCheckoutForm({
          updatedFields: {
            financeForm: {
              ...checkoutValues.financeForm,
              financeAmount: +financeAmount + cartTotal,
            },
          },
        })
      )
    );
  });

  // seeding the finance after cart reset
  seedFinanceFormValues$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(resetCart),
      concatLatestFrom(() => this.store.select(selectVehicleFinanceForm)),
      map(([, financeValues]) =>
        updateCheckoutForm({ updatedFields: { financeForm: { ...financeValues } as FinanceForm } })
      )
    );
  });

  constructor(
    private actions$: Actions,
    private cartApiClient: CartApiClient,
    private httpClient: HttpClient,
    private econtractApiService: EContractApiService,
    private router: Router,
    private notificationService: NotificationService,
    private store: Store,
    private formService: FormService,
    private dialog: PhDialog,
    private translate: TranslateService,
    private productService: ProductService
  ) {}

  private _callLoadQuote(user: UserResponse, vin: string): Observable<LoadQuote> {
    const request = {
      dealer: user.cmsDealerNumber,
      vin: vin,
    };

    const useQuoteOnlyEndpoint: boolean = environment.features.useQuoteOnlyEndpoint;

    if (user?.isRelatedDealer || useQuoteOnlyEndpoint) {
      return this.httpClient
        .post<LoadQuote>(`${environment.apiUrl}/getQuoteOnly`, request)
        .pipe(catchError((error) => throwError(() => error)));
    } else {
      return this.httpClient
        .post<LoadQuote>(`${environment.apiUrl}/getQuote`, request)
        .pipe(catchError((error) => throwError(() => error)));
    }
  }

  // this is mapper for loadquote api call
  private _mapProductsFromLoadQuote(payload: LoadQuote, markup: Markup) {
    const productState = payload.productState;

    // if there is a product state, then use that (this happens for PRIME flow)
    if (productState) {
      return {
        request: productState.productsRequest,
        response: productState.productsResponse,
        gridProducts: productState.visibleProducts,
        filters: productState.filters,
        markup: productState.markup,
      };
    }

    // else recreate the product state from available options and standard response
    const getProductsResponse = new GetProductsResponse();
    getProductsResponse.availableOptions = payload.availableOptions;
    if (environment.features.useQuoteOnlyEndpoint) {
      getProductsResponse.availableOptions.products.productItem = payload.cartState.cart.map(
        (cartItem: CartItem) => cartItem.product
      );
    }
    getProductsResponse.standardResponse = payload.StandardResponse;
    const filters = calculateFilters(getProductsResponse.availableOptions.products.productItem);

    const rawProducts = payload.availableOptions.products.productItem;
    const gridProducts = this.productService.calculateCustomerCosts(
      this.productService.productResponseDataMapper(rawProducts),
      markup,
      []
    );

    return {
      request: payload.getProductRequest,
      response: getProductsResponse,
      gridProducts: gridProducts,
      filters: filters,
      markup: markup,
    };
  }

  private _calculateCreditInsuranceMonthlyPayment(
    financeType: FinanceType,
    values: CreditInsuranceForm
  ): Partial<CreditInsuranceForm> {
    const financeAmount = +values.loanAmount;
    const interestRate = +values.interestRate / 100 / 12;
    const amortizationPeriod = +values.amortizationPeriod;
    const loanTerm = +values.loanTerm;

    return this._calculateMonthlyPayment(financeType, financeAmount, interestRate, loanTerm, amortizationPeriod);
  }

  private _calculateFinanceMonthlyPayment(financeType: FinanceType, values: FinanceForm): Partial<FinanceForm> {
    const financeAmount = +values.financeAmount;
    const interestRate = +values.apr / 100 / 12;
    const amortizationPeriod = +values.amortizationPeriod;
    const loanTerm = +values.loanTerm;

    return this._calculateMonthlyPayment(financeType, financeAmount, interestRate, loanTerm, amortizationPeriod);
  }

  private _calculateMonthlyPayment(
    financeType: FinanceType,
    financeAmount: number,
    interestRate: number,
    loanTerm: number,
    amortizationPeriod: number
  ): Partial<CreditInsuranceForm | FinanceForm> {
    let totalMonthlyPayment = 0;
    let balloonResidual = 0;

    const result: Partial<CreditInsuranceForm | FinanceForm> = {};

    switch (financeType) {
      case FinanceType.Finance:
      case FinanceType.Lease:
        if (!this._financeInputValid(financeAmount, interestRate, loanTerm)) {
          totalMonthlyPayment = 0;
        } else {
          totalMonthlyPayment =
            interestRate === 0
              ? financeAmount / loanTerm
              : financeAmount /
                ((Math.pow(1 + interestRate, loanTerm) - 1) / (interestRate * Math.pow(1 + interestRate, loanTerm)));
        }

        result.monthlyPayment = totalMonthlyPayment?.toFixed(2);
        result.residualValue = Number(0).toFixed();

        break;
      case FinanceType.Balloon:
        if (!this._balloonInputValid(financeAmount, interestRate, loanTerm, amortizationPeriod)) {
          totalMonthlyPayment = 0;
          balloonResidual = 0;
        } else {
          totalMonthlyPayment =
            amortizationPeriod >= loanTerm
              ? interestRate === 0
                ? financeAmount / amortizationPeriod
                : (interestRate * financeAmount) / (1 - (1 + interestRate) ** -amortizationPeriod)
              : 0;
          balloonResidual =
            amortizationPeriod >= loanTerm
              ? interestRate === 0
                ? financeAmount * (1 - loanTerm / amortizationPeriod)
                : (totalMonthlyPayment * (1 - (1 + interestRate) ** -(amortizationPeriod - loanTerm))) / interestRate
              : 0;
        }

        result.monthlyPayment = totalMonthlyPayment?.toFixed(2);
        result.residualValue = balloonResidual?.toFixed(2);

        break;
      case FinanceType.Cash:
        result.monthlyPayment = Number(0).toFixed(2);
        result.residualValue = Number(0).toFixed(2);

        break;
      default:
        break;
    }

    return result;
  }

  private _financeInputValid(financeAmount: number, interestRate: number, loanTerm: number): boolean {
    return !(isNaN(financeAmount) || isNaN(interestRate) || isNaN(loanTerm) || loanTerm <= 0);
  }

  private _balloonInputValid(
    financeAmount: number,
    interestRate: number,
    loanTerm: number,
    amortizationPeriod: number
  ): boolean {
    if (!this._financeInputValid(financeAmount, interestRate, loanTerm)) {
      return false;
    }

    return !(isNaN(amortizationPeriod) || amortizationPeriod <= 0 || amortizationPeriod < loanTerm);
  }

  private _pickProps(obj, props: string[]) {
    return props.reduce((acc, prop: string) => {
      acc[prop] = obj[prop];

      return acc;
    }, {});
  }

  private _objectsEqual(obj1: { [key: string]: unknown }, obj2: { [key: string]: unknown }): boolean {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
  }

  private _handleSubmitCart(
    action: { payload: SubmitCartRequest },
    vehicleState: VehicleReducer.VehicleState,
    user: UserResponse
  ): Observable<Action> {
    const productRequest = vehicleState.values;
    const locale = action.payload?.locale;
    delete action.payload?.locale;

    action.payload.dealer = user.cmsDealerNumber;
    action.payload.request = {
      ...productRequest,
      model:
        productRequest.other && productRequest.other.toLowerCase() !== 'other'
          ? productRequest.other
          : productRequest.model,
      vin: productRequest.vin || productRequest.hin || productRequest.pid || productRequest.tin,
      DOBBorrower: convertToCompatibleDateFormat(productRequest.DOBBorrower) as IComplexDate,
      DOBCoBorrower: convertToCompatibleDateFormat(productRequest.DOBCoBorrower) as IComplexDate,
      saleDate: convertToCompatibleDateFormat(productRequest.saleDate) as IComplexDate,
      inServiceDate: convertToCompatibleDateFormat(productRequest.inServiceDate) as IComplexDate,
      purchaseDate: convertToCompatibleDateFormat(productRequest.purchaseDate) as IComplexDate,
      customerTaxExempt: (productRequest.customerTaxExempt
        ? productRequest.customerTaxExempt === Options.YES
        : null) as unknown as Options,
    };
    action.payload.programId = user.programId;
    action.payload.vehicleForm = {
      msrp: productRequest.msrp,
      grossCapCost: productRequest.grossCapCost,
      apr: productRequest.apr,
      financeAmount: productRequest.financeAmount,
      registrationNumber: productRequest.registrationNumber,
      purchasePrice: productRequest.purchasePrice,
    };
    action.payload.applicationName = user.applicationName;
    action.payload.userName = user.userName;

    if (locale) {
      // needs to be removed in http interceptor
      action.payload['locale-to-be-removed'] = locale;
    }

    return this.httpClient.post<SubmitCartResponse>(`${environment.apiUrl}/savecontract`, action.payload).pipe(
      map((response: SubmitCartResponse) => {
        const isNotValidCart = response.invalidCart.some((product: CartItem) => product.valid === false);
        if (!isNotValidCart) {
          this.store.dispatch(resetSession());
        }

        const { invalidCart, submittedCart } = response;

        return submitCartSuccess({ invalidCart, submittedCart });
      }),
      catchError((error) => of(cartFailure({ error, operation: 'submitCart' })))
    );
  }

  private _handleSubmitCartWithNewRatesApi(
    action: { payload: SubmitCartModel },
    vehicleState: VehicleReducer.VehicleState,
    user: UserResponse
  ): Observable<Action> {
    const requests = [];
    let indexOfTireContract: number | null = null;
    action.payload.cart.forEach((cartItem: CartItem) => {
      if (cartItem.product.productCode === 'GMWC') {
        const request = SavePowerUpEContractRequest.generateRequest(
          action.payload,
          cartItem,
          vehicleState,
          user.cmsDealerNumber,
          user.dealerInfo
        );
        requests.push(this.econtractApiService.savePowerUpEContract(request));
      } else {
        let isTireProduct = false;
        if (environment.vendorName === Vendor.GM) {
          user.dealerInfo.agentCode = cartItem.product.programAgentCode;
        }
        if (tireCoverageCodesAllowedInSameCart.includes(cartItem.product.coverageCode)) {
          indexOfTireContract = indexOfTireContract === null ? 0 : indexOfTireContract + 1;
          isTireProduct = true;
        }

        const request = SaveAncillaryEContractRequest.generateRequest(
          action.payload,
          cartItem,
          vehicleState,
          user.cmsDealerNumber,
          user.dealerInfo,
          isTireProduct ? indexOfTireContract : null
        );
        requests.push(this.econtractApiService.saveAncillaryEContract(request));
      }
    });

    return forkJoin([...requests]).pipe(
      map((response: (EContractSuccessResponse | EContractErrorResponse)[]) => {
        const invalidCart: CartItem[] = [];
        const submittedCart: SubmittedCartItem[] = [];
        const contractsPDFToOpen: string[] = [];

        response.forEach((res: EContractSuccessResponse | EContractErrorResponse, index: number) => {
          if ((res as EContractSuccessResponse)?.standardResponse?.status === 'SUCCESS') {
            const cart = new SubmittedCartItem(
              action.payload.cart[index].product,
              action.payload.cart[index].key,
              true,
              action.payload.cart[index].message,
              action.payload.cart[index]['code'],
              (res as EContractSuccessResponse).contractId,
              action.payload.cart[index]['contractSource'],
              action.payload.cart[index]['errorType']
            );
            contractsPDFToOpen.push((res as EContractSuccessResponse)?.pdf);
            submittedCart.push(cart);
          } else {
            const cartItem: CartItem = {
              ...action.payload.cart[index],
              valid: false,
              message: (res as EContractErrorResponse).responseDescription,
              errorType: 'CREATE-CONTRACT-FAILURE',
            };
            invalidCart.push(cartItem);
          }
        });

        if (submittedCart.length && vehicleState.values.vin) {
          this.store.dispatch(deleteQuote({ dealer: user.cmsDealerNumber, vin: vehicleState.values.vin }));
        }

        if (contractsPDFToOpen.length) {
          contractsPDFToOpen.forEach((pdf: string) => openPDFInWindow(pdf));
        }

        return submitCartSuccess({ invalidCart, submittedCart });
      }),
      catchError((error) => of(cartFailure({ error, operation: 'submitCart' })))
    );
  }
}
