import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Action, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';

import { environment } from '@ph-env/environment';
import { notificationDefaultDuration } from '@ph-static-data/notification-durations';
import { forkJoin, Observable, of, throwError } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap, tap } from 'rxjs/operators';

import { NotificationService } from '@ph-core/services/notification';
import { ProductService } from '@ph-core/services/product.service';
import { SortingService } from '@ph-core/services/sorting.service';
import { calculateFilters } from '@ph-core/utils/fetch-filters';
import { CartItem } from '@ph-model/cart/cart-item.model';
import { UserResponse } from '@ph-model/login/user.response.model';
import { GetProductsRequest } from '@ph-model/request/product.request';
import {
  GetProductsResponse,
  mapRatesToGetProductsResponse,
  mapToGetProductsResponse,
  PowerUpProductsResponse,
  ProductItem,
  RatesResponse,
} from '@ph-model/response';
import { AvailableOptions } from '@ph-model/response/interface';
import { PhNotificationType } from '@ph-shared/components';
import { nextStep } from '@ph-shared/modules/shopping-stepper';
import { addToCart, resetCart } from '@ph-store/cart/cart.actions';
import { selectPrismicData } from '@ph-store/prismic/prismic.selectors';
import { selectMarkup, selectUser } from '@ph-store/user/user.selectors';
import { clearSurcharges } from '@ph-store/vehicle/vehicle.actions';

import {
  getAncillaryProducts,
  getPowerUpAndAncillaryProducts,
  getPowerUpProducts,
  getProductsFailure,
  getProductsSuccess,
} from './product.actions';

@Injectable()
export class ProductEffects {
  getAncilliaryProducts$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(getAncillaryProducts),
      concatLatestFrom(() => [
        this.store.select(selectUser),
        this.store.select(selectMarkup),
        this.store.select(selectPrismicData),
      ]),
      tap(() => this.store.dispatch(clearSurcharges())),
      mergeMap(([{ request, ratesRequest }, user, markup, prismicData]) =>
        (this._useNewRatesApi
          ? this.httpClient.get<RatesResponse>(`${environment.apiUrl}/rates`, { params: { ...ratesRequest } }).pipe(
              map((res: RatesResponse) =>
                mapRatesToGetProductsResponse(
                  res,
                  ratesRequest,
                  prismicData.product_codes_for_alternative_mappings.map((item) => item.product_code)
                )
              ),
              catchError((error) => throwError(() => error))
            )
          : this._getProductsByClient(request, user)
        ).pipe(
          map((response: GetProductsResponse) => SortingService.sortProductResponse(response)),
          map((response: GetProductsResponse) => this.productService.prepProductsForSurcharges(request, response)),
          map((response: GetProductsResponse) => this.productService.handleDealerCost(response)),
          map((response: GetProductsResponse) => {
            this.store.dispatch(resetCart());
            const filters = calculateFilters(response.availableOptions.products.productItem);
            const rawProducts = response.availableOptions.products.productItem;
            let products = this.productService.calculateCustomerCosts(
              this.productService.productResponseDataMapper(rawProducts),
              markup,
              []
            );
            if (products && products.length) {
              products = products.filter(
                (product: ProductItem) =>
                  !(product.productCode === 'YMGP' || product.productCode === 'YMBG' || product.productCode === 'YMRS')
              );
            }

            return getProductsSuccess({
              response,
              products,
              filters,
              markup,
            });
          }),
          catchError((error: HttpErrorResponse) => {
            console.error(error);

            return of(getProductsFailure({ error: error.statusText }));
          })
        )
      )
    );
  });

  getPowerUpProducts$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(getPowerUpProducts),
      concatLatestFrom(() => [this.store.select(selectMarkup), this.store.select(selectMarkup)]),
      tap(() => this.store.dispatch(clearSurcharges())),
      mergeMap(([{ request }, markup]) =>
        this.httpClient
          .get<PowerUpProductsResponse>(`${environment.apiUrl}/charger`, {
            params: { dealer: request.dealer, model: request.model, saleDate: request.saleDate, year: request.year },
          })
          .pipe(
            map((res: PowerUpProductsResponse) => mapToGetProductsResponse(res)),
            catchError((error) => throwError(() => error)),
            filter((response: GetProductsResponse) => {
              if (!response.availableOptions.products.productItem.length) {
                this.notificationService.push({
                  type: PhNotificationType.denied,
                  value: {
                    title: this.translate.instant('shoppingPage.products.notification.noProductsAvailable.title'),
                    message: this.translate.instant('shoppingPage.products.notification.noProductsAvailable.message'),
                  },
                  duration: notificationDefaultDuration,
                });
              } else {
                return true;
              }
            }),
            map((response: GetProductsResponse) => {
              this.store.dispatch(resetCart());
              const filters = calculateFilters(response.availableOptions.products.productItem);
              const rawProducts = response.availableOptions.products.productItem;
              const products = this.productService.calculateCustomerCosts(
                this.productService.productResponseDataMapper(rawProducts),
                markup,
                []
              );

              // check if products has charger product => add it to cart
              if (products?.length) {
                const chargerItem = products.find((product: ProductItem) => product.productCode === 'GMWC');

                if (chargerItem) {
                  this.store.dispatch(addToCart({ cartItem: new CartItem(chargerItem) }));
                  this.store.dispatch(nextStep());
                }
              }

              return getProductsSuccess({
                response,
                products,
                filters,
                markup,
              });
            }),
            catchError((error: HttpErrorResponse) => {
              console.error(error);

              return of(getProductsFailure({ error: error.statusText }));
            })
          )
      )
    );
  });

  getPowerUpAndAncillaryProducts$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(getPowerUpAndAncillaryProducts),
      concatLatestFrom(() => [this.store.select(selectMarkup), this.store.select(selectPrismicData)]),
      tap(() => this.store.dispatch(clearSurcharges())),
      mergeMap(([{ powerUpRequest, ratesRequest }, markup, prismicData]) =>
        forkJoin([
          this.httpClient.get<RatesResponse>(`${environment.apiUrl}/rates`, { params: { ...ratesRequest } }).pipe(
            map((res: RatesResponse) =>
              mapRatesToGetProductsResponse(
                res,
                ratesRequest,
                prismicData.product_codes_for_alternative_mappings.map((item) => item.product_code)
              )
            ),
            catchError((error) => throwError(() => error))
          ),
          this.httpClient
            .get<PowerUpProductsResponse>(`${environment.apiUrl}/charger`, {
              params: {
                dealer: powerUpRequest.dealer,
                model: powerUpRequest.model,
                saleDate: powerUpRequest.saleDate,
                year: powerUpRequest.year,
              },
            })
            .pipe(
              map((res: PowerUpProductsResponse) => mapToGetProductsResponse(res)),
              catchError((error) => throwError(() => error))
            ),
        ]).pipe(
          filter(([res1, res2]: [GetProductsResponse, GetProductsResponse]) => {
            if (
              !res1.availableOptions.products.productItem.length &&
              !res2.availableOptions.products.productItem.length
            ) {
              this.notificationService.push({
                type: PhNotificationType.denied,
                value: {
                  title: this.translate.instant('shoppingPage.products.notification.noProductsAvailable.title'),
                  message: this.translate.instant('shoppingPage.products.notification.noProductsAvailable.message'),
                },
              });
            } else {
              return true;
            }
          }),
          map(([res1, res2]: [GetProductsResponse, GetProductsResponse]) => {
            const response: GetProductsResponse = {
              ...res1,
              availableOptions: {
                ...res1.availableOptions,
                products: {
                  productItem: [
                    ...res1.availableOptions.products.productItem,
                    ...res2.availableOptions.products.productItem,
                  ],
                },
              },
            };
            this.store.dispatch(resetCart());
            const filters = calculateFilters(response.availableOptions.products.productItem);
            const rawProducts = response.availableOptions.products.productItem;
            const products = this.productService.calculateCustomerCosts(
              this.productService.productResponseDataMapper(rawProducts),
              markup,
              []
            );

            return getProductsSuccess({
              response: response,
              products: products,
              filters: filters,
              markup,
            });
          }),
          catchError((error: HttpErrorResponse) => {
            console.error(error);

            return of(getProductsFailure({ error: error.statusText }));
          })
        )
      )
    );
  });

  private _useNewRatesApi = environment.features.useNewRatesApi;

  constructor(
    private actions$: Actions,
    private notificationService: NotificationService,
    private translate: TranslateService,
    private httpClient: HttpClient,
    private store: Store,
    private productService: ProductService
  ) {}

  // Get Products based on client whether its CS 3.1 or Aggregator
  private _getProductsByClient(request: GetProductsRequest, user: UserResponse) {
    request.programId = user.programId;

    // call aggregator service if thre are related dealers
    if (user.isRelatedDealer) {
      // set variable to request data for aggregator service
      const req = this.productService.aggregateProductRequestMapper(request);

      return this.httpClient.post<GetProductsResponse>(`${environment.apiUrl}/aggregator/rates`, req).pipe(
        catchError((error) => throwError(() => error)),
        switchMap((resp: GetProductsResponse) => {
          const response = this.productService.assignPropertiesToProducts(resp, user);

          return of(response);
        })
      );
    } else {
      return this.httpClient.post<GetProductsResponse>(`${environment.apiUrl}/products`, request).pipe(
        catchError((error) => throwError(() => error)),
        map((resp: GetProductsResponse) => {
          return resp?.availableOptions?.products?.productItem
            ? resp
            : {
                standardResponse: resp as unknown,
                availableOptions: { products: { productItem: [] } } as AvailableOptions,
              };
        })
      );
    }
  }
}
