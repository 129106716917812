@if (label()) {
  <ph-form-control-label
    [label]="label()"
    [required]="!!required()"
    [disabled]="disabled || !!inactive()"
    [invalid]="showError"
    [for]="id()"
  >
    <ng-content select="[label]" />
  </ph-form-control-label>
}

<div class="input-block">
  <div class="input-prefix" #refPref>
    <ng-content select="[prefix]" />
  </div>
  <input
    ui-ph-input
    class="input"
    [ngModel]="value"
    [id]="id()"
    (ngModelChange)="updateValue($event)"
    [placeholder]="placeholder()"
    [invalid]="showError"
    [size]="inputSize()"
    [class.padding-right]="refSuff.children.length"
    [class.padding-left]="refPref.children.length"
    [numbersOnly]="onlyNumbers()"
    [decimalNumbersOnly]="!!decimalNumbersOnly()"
    [attr.maxLength]="maxLength()"
    [type]="type()"
    [disabled]="disabled || readOnly() || inactive()"
    [readOnly]="readOnly()"
  />
  <div class="input-suffix" #refSuff>
    <ng-content select="[suffix]" />
  </div>
</div>

@if (showError) {
  <ph-form-control-error [errorMessage]="errorMessage" />
}
