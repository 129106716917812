import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';

import { environment } from '@ph-env/environment';
import { of, throwError } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import { AuthService } from '@ph-core/services/auth/auth.service';
import { TokenService } from '@ph-core/services/auth/token.service';
import { PhDialog } from '@ph-core/services/dialog';
import { ErrorService } from '@ph-core/services/error/error.service';
import { LoggerService } from '@ph-core/services/logger/logger.service';
import { LazyOktaService } from '@ph-core/services/okta/lazy-okta.service';
import { RecentQuotes } from '@ph-model/api/response/recent-quotes.response.model';
import { LoadQuote } from '@ph-model/cart/load-quote.model';
import { UserResponse } from '@ph-model/login/user.response.model';
import { GetUserResponse } from '@ph-model/response/getuser.response';
import { UpdatePasswordPopupComponent } from '@ph-shared/components';
import { resetShoppingProgress, ShoppingStepsUrls, switchToStepByUrl } from '@ph-shared/modules/shopping-stepper';
import { loadQuoteSuccess, resetCart } from '@ph-store/cart/cart.actions';
import { selectConfigState } from '@ph-store/config/config.selectors';
import { resetContractSearchData } from '@ph-store/contract-search/contract-search.actions';
import { changeMarkup, resetProducts } from '@ph-store/product/product.actions';
import { resetProductForm } from '@ph-store/vehicle/vehicle.actions';

import {
  deleteQuote,
  deleteQuoteSuccess,
  emulateDealer,
  getQuote,
  getRecentQuotes,
  getRecentQuotesFailure,
  getRecentQuotesSuccess,
  getUsers,
  getUsersFailure,
  getUsersSuccess,
  login,
  loginFailure,
  loginSuccess,
  logout,
  logoutSuccess,
  resetSession,
  saveMarkup,
  ssoOktaLogin,
  ssoOktaLoginFailure,
  ssoValidateOktaPasswordResetToken,
  updatePassword,
  updatePendingContractsCount,
  updateVehicleInfo,
  updateVersion,
} from './user.actions';
import { selectEmulatedDealer, selectUser } from './user.selectors';

@Injectable()
export class UserEffects {
  login$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(login),
      concatLatestFrom(() => this.store.select(selectConfigState)),
      switchMap(([{ username, password, captcha, admin }, configState]) => {
        return this.httpClient
          .post<UserResponse>(`${environment.apiUrl}/login`, {
            username,
            password,
            application: environment.application,
            programId: configState.clientConfig.programId,
            captcha,
          })
          .pipe(
            map((response: UserResponse) => {
              this.tokenService.setToken(response.token);
              // Removing token, so it will not be stored in localStorage, addressing feedback from PEN testers
              delete response.token;

              return response.isTempPassword === 'Y'
                ? updatePassword({ response: response, admin: admin })
                : loginSuccess({ response: response, admin: admin });
            }),
            catchError((error) => of(loginFailure({ error })))
          );
      })
    );
  });

  ssoOktaLogin$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ssoOktaLogin),
      switchMap(({ oktaLoginRequest }) => {
        this.logger.log('ssoOktaLogin request received');
        const obs$ = environment.okta.SSOCredentials.idps.length
          ? this.httpClient.post<UserResponse>(`${environment.apiUrl}/okta/ssologin`, oktaLoginRequest)
          : this.httpClient.post<UserResponse>(`${environment.apiUrl}/okta/getOktaUser`, oktaLoginRequest);

        this.logger.log('ssoOktaLogin request processed successfully');

        return obs$.pipe(
          map((response: UserResponse) => {
            this.tokenService.setToken(response.token);
            // Removing token, so it will not be stored in localStorage, addressing feedback from PEN testers
            delete response.token;

            this.logger.log('ssoOktaLogin request processed successfully', response);

            return loginSuccess({ response, admin: false });
          }),
          catchError(() => {
            this.logger.log('ssoOktaLogin request failed');

            this.oktaService.getOktaAuth().then((oktaAuth) => {
              this.logger.log('ssoOktaLogin clear okta cash');

              oktaAuth?.tokenManager?.clear();
            });

            return of(ssoOktaLoginFailure());
          })
        );
      })
    );
  });

  ssoOktaLoginFailure$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(ssoOktaLoginFailure),
        tap(() => this.router.navigate(['/callback/error']))
      );
    },
    { dispatch: false }
  );

  LogInSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(loginSuccess),
        tap(({ response, admin }) => {
          if (environment.features.dynamicApplicationRedirects.length > 0) {
            if (response.application !== environment.application) {
              const redirect = environment.features.dynamicApplicationRedirects.find(
                (route) => route.application === response.application
              );
              if (redirect) {
                this.oktaService.getOktaAuth().then((oktaAuth) => {
                  oktaAuth?.tokenManager?.clear();
                  window.open(redirect.url, '_self');
                });

                return;
              }
            }
          }
          const { userName } = response;
          const pendingContractsCount = response.pendingContracts;
          const previousUser = localStorage.getItem('previousUser');
          if (previousUser !== userName) {
            this.store.dispatch(resetProductForm());
            this.store.dispatch(resetProducts());
            this.store.dispatch(resetShoppingProgress());
            this.store.dispatch(resetCart());
          }
          void this.router.navigate([admin ? '/admin/console' : '/']);

          if (pendingContractsCount) {
            this.store.dispatch(updatePendingContractsCount({ count: pendingContractsCount }));
          }
        })
      );
    },
    { dispatch: false }
  );

  ValidateOktaPasswordResetToken$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(ssoValidateOktaPasswordResetToken),
        switchMap(({ token }) =>
          this.httpClient.get<{ stateToken: string; username: string }>(
            `${environment.apiUrl}/okta/resetpasswordvalid/${token}`
          )
        ),
        tap(({ stateToken, username }) => {
          this.phDialogService.open(UpdatePasswordPopupComponent, {
            data: {
              stateToken,
              username,
              flowType: 'OKTA',
              actionType: 'RESET',
            },
          });
        })
      );
    },
    { dispatch: false }
  );

  UpdatePassword$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(updatePassword),
        tap(({ response }) => {
          // trigger popup for update password
          this.phDialogService.open(UpdatePasswordPopupComponent, {
            data: {
              username: response.userName,
              flowType: 'LEGACY',
              actionType: 'UPDATE',
            },
          });
        })
      );
    },
    { dispatch: false }
  );

  LogOut$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(logout),
      concatLatestFrom(() => [this.store.select(selectUser), this.store.select(selectEmulatedDealer)]),
      switchMap(([{ newVersion, version }, user, emulatedDealer]) => {
        if (newVersion) {
          this.store.dispatch(resetSession());
          this.store.dispatch(updateVersion({ version }));
        }

        if (user && user.userName) {
          localStorage.setItem('previousUser', user.userName);
        }

        if (emulatedDealer) {
          this.store.dispatch(resetSession());
        }

        this.authService.cancelSessionTimer();
        this.authService.cancelTokenRenewal();
        this.tokenService.deleteToken();
        localStorage.removeItem('announcementPopupViewed');
        this.store.dispatch(resetSession());
        if (environment.auth.sso) {
          return this.oktaService.oktaSignOut().then(() => {
            void this.router.navigate(['/login']);

            return logoutSuccess();
          });
        } else {
          void this.router.navigate(['/login']);

          return of(logoutSuccess());
        }
      })
    );
  });

  ResetSession$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(resetSession),
        tap(() => {
          this.errorService.hideErrorMessage();
          this.store.dispatch(resetContractSearchData());
          this.store.dispatch(resetProductForm());
          this.store.dispatch(resetProducts());
          this.store.dispatch(resetShoppingProgress());
          this.store.dispatch(resetCart());
        })
      );
    },
    { dispatch: false }
  );

  emulateDealer$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(emulateDealer),
        tap(() => this.store.dispatch(resetSession()))
      );
    },
    { dispatch: false }
  );

  UpdateVehicleInfo$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(updateVehicleInfo),
        tap(() => {
          this.errorService.hideErrorMessage();
          this.store.dispatch(resetProducts());
          this.store.dispatch(resetCart());
        })
      );
    },
    { dispatch: false }
  );

  SaveMarkupToTopFilter$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(saveMarkup),
        tap(({ markup }) => this.store.dispatch(changeMarkup({ markup })))
      );
    },
    { dispatch: false }
  );

  saveMarkupApiCall$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(saveMarkup),
        concatLatestFrom(() => this.store.select(selectUser)),
        switchMap(([{ markup }, user]) =>
          this.httpClient
            .post<void>(`${environment.apiUrl}/userdefaultadd`, { markup, username: user.userName })
            .pipe(catchError((error) => throwError(() => error)))
        )
      );
    },
    { dispatch: false }
  );

  getRecentQuotes$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(getRecentQuotes),
      concatLatestFrom(() => this.store.select(selectUser)),
      switchMap(([, user]) =>
        this.httpClient
          .post<RecentQuotes[]>(`${environment.apiUrl}/getrecentquote`, {
            dealer: user.cmsDealerNumber,
            application: environment.application,
          })
          .pipe(
            map((recentQuotes: RecentQuotes[]) => getRecentQuotesSuccess({ recentQuotes })),
            catchError((error) => of(getRecentQuotesFailure({ error })))
          )
      )
    );
  });

  getQuote$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(getQuote),
      concatLatestFrom(() => this.store.select(selectUser)),
      switchMap(([{ dealer, vin }, user]) => {
        this.store.dispatch(resetProductForm());
        this.store.dispatch(resetShoppingProgress());
        this.store.dispatch(resetCart());

        return this.callLoadQuote({ dealer, vin }, user).pipe(
          tap(() =>
            setTimeout(() => {
              this.store.dispatch(
                switchToStepByUrl({ url: ShoppingStepsUrls.CartSummary, options: { updateProgress: true } })
              );
            }, 200)
          ),
          map((quote: LoadQuote) => loadQuoteSuccess({ quote })),
          catchError(() => of(loginFailure({ error: 'Login Failure' })))
        );
      })
    );
  });

  deleteQuote$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(deleteQuote),
      switchMap(({ dealer, vin }) =>
        this.httpClient.post<RecentQuotes[]>(`${environment.apiUrl}/cartdelete`, { dealer, vin }).pipe(
          map((response: RecentQuotes[]) => deleteQuoteSuccess({ response })),
          catchError(() => of(loginFailure({ error: 'Login Failure' })))
        )
      )
    );
  });

  getUsers$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(getUsers),
      concatLatestFrom(() => this.store.select(selectUser)),
      switchMap(([, user]) =>
        this.httpClient
          .post<GetUserResponse>(`${environment.apiUrl}/getusers`, {
            cmsDealerNumber: user.cmsDealerNumber,
            applicationId: user.applicationId,
          })
          .pipe(
            map((userResponse: GetUserResponse) => getUsersSuccess({ userResponse })),
            catchError((error) => of(getUsersFailure({ error })))
          )
      )
    );
  });

  constructor(
    private actions$: Actions,
    private errorService: ErrorService,
    private router: Router,
    private authService: AuthService,
    private store: Store,
    private oktaService: LazyOktaService,
    private phDialogService: PhDialog,
    private httpClient: HttpClient,
    private tokenService: TokenService,
    private logger: LoggerService
  ) {}

  // This is a duplicate of callLoadQuote in Cart
  // duplication was required because of the dependency created
  // in cart.effects will need to refactor
  // TODO
  callLoadQuote(request: { dealer: string; vin: string }, user: UserResponse) {
    const useQuoteOnlyEndpoint: boolean = environment.features.useQuoteOnlyEndpoint;

    return user?.isRelatedDealer || useQuoteOnlyEndpoint
      ? this.httpClient
          .post<LoadQuote>(`${environment.apiUrl}/getQuoteOnly`, request)
          .pipe(catchError((error) => throwError(() => error)))
      : this.httpClient
          .post<LoadQuote>(`${environment.apiUrl}/getQuote`, request)
          .pipe(catchError((error) => throwError(() => error)));
  }
}
