import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Action, Store } from '@ngrx/store';

import { environment } from '@ph-env/environment';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';

import { EContractApiService } from '@ph-core/services/econtract-api.service';
import { HistoryService } from '@ph-core/services/history.service';
import { RemitService } from '@ph-core/services/remit.service';
import { ContractHistoryResponse, ContractType } from '@ph-model/api';
import { PendingContractResponse } from '@ph-model/api/response/pending-contract.response.model';
import { EContractStatusType } from '@ph-model/e-contract-status-type';
import { Filter } from '@ph-model/filters/filter';
import { SearchFilter } from '@ph-model/filters/search-filter';
import { UserResponse } from '@ph-model/login/user.response.model';
import { GetContractHistoryRequest } from '@ph-model/request/get-contract-history.request';
import { UpdateStatusEContractRequest } from '@ph-model/request/save-power-up-econtract';
import { UndoDeclineContractsRequest } from '@ph-model/request/undo-decline-contracts.request';
import { selectUser } from '@ph-store/user/user.selectors';

import {
  getContracts,
  getContractsSuccess,
  historyFailure,
  undoDeclineContract,
  undoDeclineContractSuccess,
} from './history.actions';
import * as HistoryReducer from './history.reducer';
import { selectHistoryState } from './history.selectors';

@Injectable()
export class HistoryEffects {
  constructor(
    private actions$: Actions,
    private httpClient: HttpClient,
    private econtractApiService: EContractApiService,
    private store: Store
  ) {}

  // eslint-disable-next-line @typescript-eslint/member-ordering
  getContracts$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(getContracts),
      concatLatestFrom(() => [this.store.select(selectHistoryState), this.store.select(selectUser)]),
      mergeMap(([{ firstName, lastName, vin, searchAllDealers }, , user]) => {
        const allDealers = JSON.parse(JSON.stringify(user.allDealers));

        if (allDealers && allDealers.length === 1) {
          allDealers[0].cmsDealerNumber = user.cmsDealerNumber;
        }

        const request: GetContractHistoryRequest = {
          cmsDealerNumber: user.cmsDealerNumber,
          lastName: lastName?.toUpperCase() || '',
          firstName: firstName?.toUpperCase() || '',
          vin: vin?.toUpperCase() || '',
          programId: user.programId,
          allDealers: allDealers,
        };

        if (searchAllDealers) {
          request.searchAllDealers = true;
        }

        return this.httpClient.post<ContractHistoryResponse>(`${environment.apiUrl}/getcontracthistory`, request).pipe(
          map((response: ContractHistoryResponse) => HistoryService.adaptStatusesToPPWL(response.contracts)),
          tap((contracts: ContractType[]) => RemitService.handleDealerCost(contracts)),
          map((contracts: ContractType[]) =>
            getContractsSuccess({ contracts: { contracts }, filters: HistoryService.fetchFilters(contracts) })
          ),
          catchError((error) => {
            console.error(error);

            return of(historyFailure({ error }));
          })
        );
      })
    );
  });

  // eslint-disable-next-line @typescript-eslint/member-ordering
  undoDeclineContract$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(undoDeclineContract),
      concatLatestFrom(() => [this.store.select(selectHistoryState), this.store.select(selectUser)]),
      mergeMap(([{ contractType }, historyState, user]) =>
        environment.features.useNewSaveContractApi
          ? this._undoDeclineContractWithNewRatesApi(contractType, historyState)
          : this._undoDeclineContract(contractType, historyState, user)
      )
    );
  });

  private _undoDeclineContract(
    contractType: ContractType,
    historyState: HistoryReducer.HistoryState,
    user: UserResponse
  ): Observable<Action> {
    const allDealers = JSON.parse(JSON.stringify(user.allDealers));

    if (allDealers && allDealers.length === 1) {
      allDealers[0].cmsDealerNumber = user.cmsDealerNumber;
    }

    const request: UndoDeclineContractsRequest = {
      cmsDealerNumber: user.cmsDealerNumber,
      programId: user.programId,
      allDealers: allDealers,
      contract: contractType,
    };

    return this.httpClient.post<PendingContractResponse>(`${environment.apiUrl}/undodecline`, request).pipe(
      catchError((error) => throwError(() => error)),
      map(() => {
        let contracts = historyState.contracts;
        if (request.contract.status) {
          contracts = this._prepareContract(historyState.contracts, request.contract.econContractNumber);
        }
        const newFilters = this._updateSearchFilter(
          historyState.contractFilters,
          HistoryService.fetchFilters(contracts)
        );

        return undoDeclineContractSuccess({
          contracts,
          filters: newFilters,
          gridContracts: HistoryService.filter(newFilters, contracts),
        });
      }),
      // If request fails, dispatch failed action
      catchError((error) => {
        console.error(error);

        return of(historyFailure({ error }));
      })
    );
  }

  private _undoDeclineContractWithNewRatesApi(
    contractType: ContractType,
    historyState: HistoryReducer.HistoryState
  ): Observable<Action> {
    const request = new UpdateStatusEContractRequest(contractType.econContractNumber, EContractStatusType.Pending);

    return this.econtractApiService.updateStatusEContract(request).pipe(
      map(() => {
        let contracts = historyState.contracts;
        if (contractType.status) {
          contracts = this._prepareContract(historyState.contracts, contractType.econContractNumber);
        }
        const newFilters = this._updateSearchFilter(
          historyState.contractFilters,
          HistoryService.fetchFilters(contracts)
        );

        return undoDeclineContractSuccess({
          contracts,
          filters: newFilters,
          gridContracts: HistoryService.filter(newFilters, contracts),
        });
      }),
      // If request fails, dispatch failed action
      catchError((error) => {
        console.error(error);

        return of(historyFailure({ error }));
      })
    );
  }

  private _prepareContract(contracts: ContractType[], econContractNumber: string): ContractType[] {
    return contracts.map((contract: ContractType) => {
      if (contract.econContractNumber === econContractNumber) {
        const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

        const year = new Date().getFullYear();
        const month = months[new Date().getMonth()];
        const day = new Date().getDate();

        contract.modifiedDate = `${month} ${day}, ${year}`;
        contract.status = 'Pending';
        contract.statusCode = 'P';

        return contract;
      }

      return contract;
    });
  }

  private _updateSearchFilter(oldFilters: SearchFilter, newFilters: SearchFilter): SearchFilter {
    if (oldFilters.searchPhrase) {
      newFilters.searchPhrase = oldFilters.searchPhrase;
    }
    newFilters.month = this._updateFilters(oldFilters.month, newFilters.month);
    newFilters.status = this._updateFilters(oldFilters.status, newFilters.status);

    return newFilters;
  }

  private _updateFilters(oldFilters: Filter[], newFilters: Filter[]): Filter[] {
    let updatedFilters: Filter[] = [];
    if (oldFilters.some((filter: Filter) => filter.selected)) {
      updatedFilters = newFilters.map((filter: Filter) => {
        const oldFilter = oldFilters.find((f: Filter) => f.name === filter.name);

        return oldFilter && oldFilter.selected ? { ...filter, selected: true } : filter;
      });
    } else {
      return newFilters;
    }

    return updatedFilters;
  }
}
